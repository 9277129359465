import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Si vous utilisez React Router

function TrackingPage() {
  const { guid } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Appeler l'API avec le guid
    fetch(`https://api.labeille.xyz/api/membership/${guid}`)
      .then(r => {
        if (!r.ok) throw new Error('Not found');
        return r.json();
      })
      .then(d => setData(d))
      .catch(err => setError("Aucune inscription trouvée."));
  }, [guid]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!data) {
    return <div>Chargement...</div>;
  }

  const handleDownloadPdf = () => {
    window.open(`https://api.labeille.xyz/api/membership/${guid}/pdf`, '_blank');
  };

  const handleDownloadCerfa = () => {
      if (data.document_id) {
        window.open(`https://api.labeille.xyz/api/membership/${guid}/cerfa`, '_blank');
      } else {
        alert("Aucun document Cerfa n'a été soumis.");
      }
    };


  return (
    <div className="container">
      <h1>Suivi de votre adhésion</h1>
      <p><strong>Nom :</strong> {data.nom}</p>
      <p><strong>Prénom :</strong> {data.prenom}</p>
      <p><strong>Email :</strong> {data.email}</p>
      <p><strong>Adhésion au PSE :</strong> {data.pse ? 'Oui' : 'Non'}</p>
      <p><strong>Statut :</strong> {data.status.paid ? 'Réglé' : 'Non réglé'}, {data.status.processed ? 'Traité' : 'Non traité'}</p>
      
      <div className="mt-4 p-3 border rounded" style={{backgroundColor: '#f5f5f5'}}>
        <h5>Récapitulatif des frais</h5>
        <table className="table table-sm">
          <tbody>
            <tr>
              <th scope="row">Cotisation annuelle</th>
              <td>{data.fees.cotisation.toFixed(2)} €</td>
            </tr>
            <tr>
              <th scope="row">Section Apicole</th>
              <td>{data.fees.section_apicole.toFixed(2)} € ({data.nombre_ruches} ruches × 0,20€, plafonné à 100€)</td>
            </tr>
            <tr>
              <th scope="row">Écotaxe</th>
              <td>{data.fees.ecotaxe.toFixed(2)} € ({data.nombre_ruches} ruches × 0,07€)</td>
            </tr>
            <tr>
              <th scope="row">Santé de l'Abeille</th>
              <td>{data.fees.sante_abeille.toFixed(2)} € (option activée)</td>
            </tr>
            <tr className="table-active">
              <th scope="row">Total</th>
              <td><strong>{data.fees.total.toFixed(2)} €</strong></td>
            </tr>
          </tbody>
        </table>
      </div>

      
      
      <div className="mt-4">
        <button className="btn btn-primary me-2" onClick={handleDownloadPdf}>
          Télécharger l'adhésion (PDF)
        </button>
        {data.document_id && (
          <button className="btn btn-secondary" onClick={handleDownloadCerfa}>
            Télécharger le document Cerfa
          </button>
        )}
      </div>

    </div>
  );
}

export default TrackingPage;
