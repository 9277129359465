import React, { useState, useEffect } from 'react';
import axios from 'axios';

function AddressAutocomplete({ value, onChange, onSelect }) {
  const [inputValue, setInputValue] = useState(value || '');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (inputValue.length > 3) {
      axios.get(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(inputValue)}&limit=5`)
        .then(res => {
          setSuggestions(res.data.features);
        });
    } else {
      setSuggestions([]);
    }
  }, [inputValue]);

  const handleSelect = (feature) => {
    const addr = feature.properties.name || '';
    const city = feature.properties.city || '';
    const postcode = feature.properties.postcode || '';
    const fullAddress = feature.properties.label || addr;

    // On met à jour le parent
    onSelect(fullAddress, postcode, city);
    setInputValue(fullAddress);
    setSuggestions([]);
  };

  return (
    <div style={{position: 'relative'}}>
      <input
        className="form-control"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Saisissez une adresse"
      />
      {suggestions.length > 0 && (
        <ul className="list-group" style={{position:'absolute', top:'100%', width:'100%', zIndex:999}}>
          {suggestions.map(s => (
            <li key={s.properties.id}
                className="list-group-item list-group-item-action"
                onClick={() => handleSelect(s)}
                style={{cursor:'pointer'}}>
              {s.properties.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default AddressAutocomplete;
