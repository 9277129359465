import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddressAutocomplete from '../components/AddressAutocomplete';
import { Modal } from 'bootstrap'; // Assurez-vous d'importer bootstrap JS ou utilisez un modal React custom.

function MembershipForm() {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    telephone: '',
    nombre_ruches: '',
    napi: '',
    siret: '',
    adresse: '',
    code_postal: '',
    ville: '',
    sante_abeille: false,
    ecotaxe: false,
    pse: false,
    mode_paiement: ''
  });

  const [cerfaFile, setCerfaFile] = useState(null);

  const [fees, setFees] = useState({
    cotisation: 15,
    section_apicole: 0,
    ecotaxe: 0,
    sante_abeille: 0,
    payment_fees: 0,
    total: 15
  });

  const [errors, setErrors] = useState({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [guid, setGuid] = useState(null);

  // Fonction de calcul des frais
  const calculateFees = () => {
    const nbRuches = parseInt(formData.nombre_ruches, 10) || 0;
    const cotisation = 15.0;
    const section_apicole = nbRuches < 500 ? nbRuches * 0.20 : 100.0;
    const ecotaxe = formData.ecotaxe ? nbRuches * 0.07 : 0;
    const sante_abeille = formData.sante_abeille ? 22.0 : 0.0;
    let total = cotisation + section_apicole + ecotaxe + sante_abeille;

    // Frais de paiement en ligne
    let payment_fees = 0;
    if (formData.mode_paiement === 'en_ligne') {
      payment_fees = total * 0.014 + 0.25;
      total += payment_fees;
    }

    return {
      cotisation,
      section_apicole,
      ecotaxe,
      sante_abeille,
      payment_fees,
      total
    };
  };

  const validateForm = () => {
    let newErrors = {};

    // Validation email
    if (!formData.email.trim()) {
      newErrors.email = "L'email est obligatoire.";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        newErrors.email = "Format d'email invalide.";
      }
    }

    // Nombre de ruches
    const nb = parseInt(formData.nombre_ruches, 10);
    if (isNaN(nb) || nb < 0) {
      newErrors.nombre_ruches = "Le nombre de ruches doit être un entier positif.";
    }

    // SIRET obligatoire si écotaxe cochée
    if (formData.ecotaxe && formData.siret.trim() === '') {
      newErrors.siret = "Le SIRET est obligatoire si l’écotaxe est appliquée.";
    }

    // Champs obligatoires
    if (formData.nom.trim() === '') {
      newErrors.nom = "Le nom est obligatoire.";
    }
    if (formData.prenom.trim() === '') {
      newErrors.prenom = "Le prénom est obligatoire.";
    }
    if (formData.telephone.trim() === '') {
      newErrors.telephone = "Le téléphone est obligatoire.";
    }
    if (formData.adresse.trim() === '') {
      newErrors.adresse = "L'adresse est obligatoire.";
    }

    // PSE : cerfa obligatoire si PSE coché
    if (formData.pse && !cerfaFile) {
      newErrors.cerfa = "Le Cerfa est obligatoire si vous adhérez au PSE.";
    }

    // Mode de paiement obligatoire
    if (formData.mode_paiement === '') {
      newErrors.mode_paiement = "Veuillez sélectionner un mode de paiement.";
    }

    setErrors(newErrors);
    setCanSubmit(Object.keys(newErrors).length === 0);
  };

  useEffect(() => {
    const newFees = calculateFees();
    setFees(newFees);
    validateForm();
    // eslint-disable-next-line
  }, [formData, cerfaFile]);

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
    setSubmitError(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0] || null;
    setCerfaFile(file);
  };

  const handleAddressChange = (val) => {
    setFormData({...formData, adresse: val});
  };

  const handleAddressSelect = (fullAddress, postcode, city) => {
    setFormData({
      ...formData,
      adresse: fullAddress,
      code_postal: postcode,
      ville: city
    });
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      if (!canSubmit) return;
  
      setLoading(true);
      setSubmitError(null);
  
      try {
        const formPayload = new FormData();
        for (const key in formData) {
          formPayload.append(key, formData[key]);
        }
  
        // Convertir le nombre de ruches en entier
        formPayload.set('nombre_ruches', parseInt(formData.nombre_ruches, 10) || 0);
  
        // Ajouter le fichier Cerfa si disponible
        if (cerfaFile) {
          formPayload.append('document', cerfaFile); // Le champ doit correspondre au backend
        }
  
        // Ajouter l'adhésion au PSE (true ou false)
        formPayload.set('pse', formData.pse ? 'true' : 'false'); // Convertir en chaîne pour le backend
  
        // Appel à l'API
        const res = await axios.post('https://api.labeille.xyz/api/membership', formPayload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        // Récupérer l'identifiant après succès
        setGuid(res.data.id);
        setShowModal(true); // Afficher la modale après succès
      } catch (err) {
        console.error(err);
        setSubmitError("Une erreur est survenue lors de l’enregistrement.");
      }
  
      setLoading(false);
  };


  const paymentInstructions = () => {
    if (!fees || !fees.total) {
      return <div>Calcul des frais en cours...</div>;
    }
  
    const totalAmount = fees.total.toFixed(2); // Assurez-vous d'afficher le total avec 2 décimales
  
    if (formData.mode_paiement === 'virement') {
      return (
        <div>
          <p>Veuillez effectuer un virement pour un montant de <strong>{totalAmount} €</strong> sur le RIB suivant :</p>
          <p><strong>RIB :</strong> FR76 1027 8025 8000 0203 3780 153</p>
        </div>
      );
    } else if (formData.mode_paiement === 'cheque') {
      return (
        <div>
          <p>Veuillez envoyer un chèque de <strong>{totalAmount} €</strong> à l’adresse suivante :</p>
          <p><strong>Adresse :</strong> Mme Jacqueline PERRONNEAU, secrétariat GDSA21, 67 av. de la Gare, 21220 GEVREY-CHAMBERTIN</p>
        </div>
      );
    } else if (formData.mode_paiement === 'en_ligne') {
      return (
        <div>
          <p>Le montant à régler est de <strong>{totalAmount} €</strong>. Un lien de paiement en ligne vous sera communiqué ultérieurement.</p>
        </div>
      );
    }
  
    return <div>Aucun mode de paiement sélectionné.</div>;
  };


  return (
    <>
      <form onSubmit={handleSubmit}>
        {submitError && <div className="alert alert-danger">{submitError}</div>}

        <div className="row">
          {/* Nom et Prénom */}
          <div className="col-md-6 mb-3">
            <label>Nom</label>
            <input 
              className={`form-control ${errors.nom ? 'is-invalid' : ''}`} 
              name="nom" 
              value={formData.nom} 
              onChange={handleChange} 
            />
            {errors.nom && <div className="invalid-feedback">{errors.nom}</div>}
          </div>
          <div className="col-md-6 mb-3">
            <label>Prénom</label>
            <input 
              className={`form-control ${errors.prenom ? 'is-invalid' : ''}`} 
              name="prenom" 
              value={formData.prenom} 
              onChange={handleChange} 
            />
            {errors.prenom && <div className="invalid-feedback">{errors.prenom}</div>}
          </div>
        </div>
        
        <div className="row">
          {/* Email et Téléphone */}
          <div className="col-md-6 mb-3">
            <label>Email</label>
            <input 
              className={`form-control ${errors.email ? 'is-invalid' : ''}`} 
              name="email" 
              value={formData.email} 
              onChange={handleChange} 
            />
            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
          </div>
          <div className="col-md-6 mb-3">
            <label>Téléphone</label>
            <input 
              className={`form-control ${errors.telephone ? 'is-invalid' : ''}`} 
              name="telephone" 
              value={formData.telephone} 
              onChange={handleChange} 
            />
            {errors.telephone && <div className="invalid-feedback">{errors.telephone}</div>}
          </div>
        </div>
        
        <div className="row">
          {/* SIRET */}
          <div className="col-12 mb-3">
            <label>SIRET</label>
            <input 
              className={`form-control ${errors.siret ? 'is-invalid' : ''}`} 
              name="siret" 
              value={formData.siret} 
              onChange={handleChange} 
            />
            {errors.siret && <div className="invalid-feedback">{errors.siret}</div>}
          </div>
        </div>
        
        {/* PSE section */}
        <div className="mb-3 p-3 bg-light border rounded">
          <h5>Information PSE (Plan Sanitaire d’Élevage)</h5>
          <p className="mb-1">L'adhésion au PSE est gratuite et <b>obligatoire pour pouvoir commander des médicaments</b>.</p>
          <p className="mb-1">Si vous cochez le PSE, vous devez fournir le PDF du Cerfa de déclaration des ruches.</p>
          <div className="form-check mt-3">
            <input className="form-check-input"
                   type="checkbox"
                   name="pse"
                   id="pse"
                   checked={formData.pse}
                   onChange={handleChange} />
            <label className="form-check-label" htmlFor="pse">Adhésion au PSE</label>
          </div>
        </div>
        
        <div className="row">
          {/* NAPI et Nombre de Ruches */}
          <div className="col-md-6 mb-3">
            <label>NAPI</label>
            <input 
              className="form-control" 
              name="napi" 
              value={formData.napi} 
              onChange={handleChange} 
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Nombre de ruches</label>
            <input 
              className={`form-control ${errors.nombre_ruches ? 'is-invalid' : ''}`} 
              type="number" 
              name="nombre_ruches" 
              value={formData.nombre_ruches} 
              onChange={handleChange} 
            />
            {errors.nombre_ruches && <div className="invalid-feedback">{errors.nombre_ruches}</div>}
          </div>
        </div>

        {formData.pse && (
          <div className="mb-3">
            <label>Déclaration de détention et d'emplacement de ruches (Cerfa 13995*07 en PDF)</label>
            <input className={`form-control ${errors.cerfa ? 'is-invalid' : ''}`}
                   type="file"
                   accept="application/pdf"
                   onChange={handleFileChange} />
            {errors.cerfa && <div className="invalid-feedback">{errors.cerfa}</div>}
          </div>
        )}

        {/* Adresse pleine largeur */}
        <div className="mb-3">
          <label>Adresse</label>
          <AddressAutocomplete
            value={formData.adresse}
            onChange={handleAddressChange}
            onSelect={handleAddressSelect}
          />
          {errors.adresse && <div className="text-danger">{errors.adresse}</div>}
        </div>

        <div className="row">
          <div className="col-md-3 mb-3">
            <label>Code Postal</label>
            <input className="form-control"
                   name="code_postal"
                   value={formData.code_postal}
                   onChange={handleChange} />
          </div>
          <div className="col-md-9 mb-9">
            <label>Ville</label>
            <input className="form-control"
                   name="ville"
                   value={formData.ville}
                   onChange={handleChange} />
          </div>
        </div>

        {/* Section Options */}
        <div className="mb-3 p-3 bg-light border rounded">
          <h5>Options</h5>
          <div className="form-check mt-3">
            <input className="form-check-input"
                   type="checkbox"
                   name="sante_abeille"
                   id="santeAbeille"
                   checked={formData.sante_abeille}
                   onChange={handleChange} />
            <label className="form-check-label" htmlFor="santeAbeille">Santé de l'Abeille (22€)</label>
          </div>
          <div className="form-check mt-3">
            <input className="form-check-input"
                   type="checkbox"
                   name="ecotaxe"
                   id="ecotaxe"
                   checked={formData.ecotaxe}
                   onChange={handleChange} />
            <label className="form-check-label" htmlFor="ecotaxe">Appliquer l’écotaxe (0,07€/ruche)</label>
          </div>
        </div>

        <div className="mb-3">
          <h5>Mode de paiement</h5>
          <div className="form-check">
            <input className={`form-check-input ${errors.mode_paiement ? 'is-invalid' : ''}`} 
                   type="radio" 
                   name="mode_paiement" 
                   id="paiementVirement" 
                   value="virement" 
                   checked={formData.mode_paiement === 'virement'} 
                   onChange={handleChange} />
            <label className="form-check-label" htmlFor="paiementVirement">Virement bancaire</label>
          </div>
          <div className="form-check">
            <input className={`form-check-input ${errors.mode_paiement ? 'is-invalid' : ''}`} 
                   type="radio" 
                   name="mode_paiement" 
                   id="paiementCheque" 
                   value="cheque" 
                   checked={formData.mode_paiement === 'cheque'} 
                   onChange={handleChange} />
            <label className="form-check-label" htmlFor="paiementCheque">Chèque</label>
          </div>
          {/* <div className="form-check">
            <input className={`form-check-input ${errors.mode_paiement ? 'is-invalid' : ''}`} 
                   type="radio" 
                   name="mode_paiement" 
                   id="paiementEnLigne" 
                   value="en_ligne" 
                   checked={formData.mode_paiement === 'en_ligne'} 
                   onChange={handleChange} />
            <label className="form-check-label" htmlFor="paiementEnLigne">Paiement en ligne (frais Stripe)</label>
          </div> */}
          {errors.mode_paiement && <div className="text-danger">{errors.mode_paiement}</div>}
        </div>

        <div className="mt-4 p-3 border rounded" style={{backgroundColor:'#f5f5f5'}}>
          <h5>Récapitulatif des frais</h5>
          <table className="table table-sm">
            <tbody>
              <tr>
                <th scope="row">Cotisation annuelle</th>
                <td>{fees.cotisation.toFixed(2)} €</td>
              </tr>
              <tr>
                <th scope="row">Section Apicole</th>
                <td>{fees.section_apicole.toFixed(2)} € ({formData.nombre_ruches || 0} ruches × 0,20€, plafonné à 100€)</td>
              </tr>
              <tr>
                <th scope="row">Écotaxe</th>
                <td>{fees.ecotaxe.toFixed(2)} € {formData.ecotaxe && `( ${formData.nombre_ruches || 0} ruches × 0,07€ )`}</td>
              </tr>
              <tr>
                <th scope="row">Santé de l'Abeille</th>
                <td>{fees.sante_abeille.toFixed(2)} € {formData.sante_abeille && "(option activée)"}</td>
              </tr>
              {formData.mode_paiement === 'en_ligne' && (
                <tr>
                  <th scope="row">Frais de paiement (Stripe)</th>
                  <td>{fees.payment_fees.toFixed(2)} €</td>
                </tr>
              )}
              <tr className="table-active">
                <th scope="row">Total</th>
                <td><strong>{fees.total.toFixed(2)} €</strong></td>
              </tr>
            </tbody>
          </table>
        </div>

        <button className="btn btn-primary mt-3" type="submit" disabled={!canSubmit || loading}>
          {loading ? 'En cours...' : 'Adhérer au GDSA21'}
        </button>
      </form>

      {/* Modal après envoi */}
      {showModal && (
        <div className="modal fade show" style={{display: 'block'}} tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Adhésion enregistrée</h5>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body">
                <p>Votre adhésion a été enregistrée. Pour finaliser, veuillez procéder au paiement.</p>
                {paymentInstructions()}
                <p>Vous pourrez suivre l'avancement de votre dossier à cette adresse :</p>
                <p><a href={`https://www.labeille.xyz/tracking/${guid}`} target="_blank" rel="noopener noreferrer">
                  https://www.labeille.xyz/tracking/{guid}
                </a></p>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={() => setShowModal(false)}>Fermer</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MembershipForm;
