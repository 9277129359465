import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MembershipForm from './pages/MembershipForm';
import TrackingPage from './pages/TrackingPage';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <div className="header">
        <img src="/logo.png" alt="GDSA21" style={{ height: '60px', marginRight: '15px' }} />
        <h1>Adhésion GDSA21 – 2025</h1>
      </div>

      <div className="container my-5">
        <div className="form-card shadow">
          <Routes>
            {/* Route pour le formulaire */}
            <Route path="/" element={<MembershipForm />} />
            {/* Route pour le suivi */}
            <Route path="/tracking/:guid" element={<TrackingPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
